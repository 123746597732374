.video
{   
    top: 10vh;
    left: 15vw;
    position: absolute;
    padding-left: 3%;
    z-index: 0;
    filter: drop-shadow(0 0 0.75rem rgb(251, 255, 253));

}
.video-on-top {
    z-index: 100;
}
.induction-controls {
    height: 3.4vh;
    z-index: 100;
    left: 5vw;
    position: relative;
    width:60vw;
    font-size: 2vw;
    padding-left: 3%;    
    margin: 0 17.5vw; /*17.5 fordi auto virker ikke når der er left 5vw. fordi width = 60vw + left 5 vw == 65 --> 100 - 65 == 35 --> 35 / 2 == 17.5*/
    padding: 1vh;

    border-radius: 1.5vw;
    border: 2px solid rgba(255, 255, 255, .4);
}

.controls-inner-wrapper{
    width: 10vw;
    display: inline-block;
    position: absolute;
    left: -1vh;
    height: 60%;
}

.restart-inner-wrapper{
    position: absolute;
    left: 100vh;
    top: 20%;
    height: 60%;
}

.chapter-title {
    text-align: initial;
    margin-left: 10vw;
    margin-right: 4vw;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: orsted-font-regular;
    font-size: 80%;
}


.center {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


